import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const siteTitle = site.siteMetadata?.title
  const siteDescription = site.siteMetadata?.description

  return {
    siteTitle,
    siteDescription,
  }
}
