import * as React from "react"
import PropTypes from "prop-types"
import { useSiteMetadata } from "../hooks/useSitemetadata"

function Seo({ description, title, children }) {
  const { siteTitle, siteDescription } = useSiteMetadata()
  const pageDescription = description || siteDescription

  return (
    <>
      <title>{siteTitle ? `${title} | ${siteTitle}` : title}</title>
      <meta name="description" content={pageDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={pageDescription} />
      {children}
    </>
  )
}

Seo.defaultProps = {
  title: "",
  description: "",
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Seo
